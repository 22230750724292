import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl, useIntl } from '../../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../../util/uiHelpers';
import { propTypes } from '../../../../util/types';
import { formatMoney, formatMoneyRoundWithoutDecimals } from '../../../../util/currency';
import { ensureListing, ensureUser } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
const { Money } = sdkTypes;
import { AspectRatioWrapper, ResponsiveImage } from '../../../../components';

import css from './ListingCard.module.css';

// Utility function to get the first image
const getFirstImage = (currentListing, listingType) => {
  let orderedImages = [];

  if (listingType === 'store-listing') {
    const storeImagesOrder = currentListing.attributes?.publicData?.storeImagesOrder || [];
    orderedImages = [...currentListing.images].sort((a, b) => {
      const aIndex = storeImagesOrder.indexOf(a.id?.uuid);
      const bIndex = storeImagesOrder.indexOf(b.id?.uuid);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  } else {
    const displayImagesOrder = currentListing?.attributes?.publicData?.displayImagesOrder || [];
    orderedImages = [...currentListing.images].sort((a, b) => {
      const aIndex = displayImagesOrder.indexOf(a.id?.uuid);
      const bIndex = displayImagesOrder.indexOf(b.id?.uuid);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }
  return orderedImages.length > 0 ? orderedImages[0] : null;
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const intl = useIntl();

  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    activeListing,
  } = props;
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const { finalDisplayRate, listingType } = currentListing.attributes.publicData;

  const firstImage = getFirstImage(currentListing, listingType) || {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'display-card',
  } = config.layout.displayImage;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const handleClick = () => {
    if (setActiveListing) {
      setActiveListing(currentListing);
    }
  };

  const active = currentListing?.id?.uuid === activeListing?.id?.uuid;

  const price = (finalDisplayRate && finalDisplayRate?.amount) && new Money(finalDisplayRate.amount, config.currency);
  const formattedFinalDisplayRate = price ? formatMoneyRoundWithoutDecimals(intl, price) : null;

  return (
    <div
      className={classNames(css.root, { [css.active]: active })}
      onClick={handleClick}
    >
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        {formattedFinalDisplayRate &&
          <FormattedMessage id="ListingCard.priceToDisplay" values={{ price: formattedFinalDisplayRate }} />
        }
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
