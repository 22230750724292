import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';

// import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import { AspectRatioWrapper, ResponsiveImage } from '../../components';

import css from './ListingPage.module.css';

const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
      ? { aspectWidth: 4, aspectHeight: 3 }
      : hasDimensions
        ? { aspectWidth: w, aspectHeight: h }
        : { aspectWidth: 1, aspectHeight: 1 };
};

const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react'), {
      ssr: false,
    });
const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react'), {
      ssr: false,
    });
const Pagination =
  typeof window !== 'undefined'
    ? require('swiper/modules').Pagination
    : dynamic(() => import('swiper/modules'), {
      ssr: false,
    });
const Autoplay =
  typeof window !== 'undefined'
    ? require('swiper/modules').Autoplay
    : dynamic(() => import('swiper/modules'), {
      ssr: false,
    });

const SectionGallery = props => {
  const { listing, variantPrefix, intl, config } = props;
  const images = listing && listing.images ? listing.images : [];
  // const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const imageVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  useEffect(() => {
    // Load swiper CSS only in the browser
    if (typeof window !== 'undefined') {
      require('swiper/css');
      require('swiper/css/bundle');
    }
  }, []);

  const storeImagesOrder = listing?.attributes?.publicData?.storeImagesOrder || [];
  const orderedImages = [...images].sort((a, b) => {
    const aIndex = storeImagesOrder?.indexOf(a.id?.uuid);
    const bIndex = storeImagesOrder?.indexOf(b.id?.uuid);
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  // const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(orderedImages?.[0], imageVariants[0]);
  const { aspectWidth, aspectHeight } = config.layout.listingImage || {};

  return (
    // <div className={css.productGallery} data-testid="carousel">
    //   <ListingImageGallery
    //     images={orderedImages}
    //     imageVariants={imageVariants}
    //     thumbnailVariants={thumbnailVariants}
    //     isMainSlider={isMainSlider}
    //   />
    // </div>
    <div className={css.productGallery}>
      {orderedImages &&
        <Swiper
          className={css.swiperForListings}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          loop={true}
          // spaceBetween={20}
          autoHeight={false}
          slidesPerView={1.5}
        >
          {orderedImages?.map((l, index) => (
            <SwiperSlide key={l.id.uuid}>
              <AspectRatioWrapper
                width={aspectWidth}
                height={aspectHeight}
                className={css.itemWrapper}
              >
                <ResponsiveImage
                  rootClassName={css.item}
                  image={l}
                  alt={intl.formatMessage(
                    { id: 'ListingImageGallery.imageAltText' },
                    { index: index + 1, count: orderedImages.length }
                  )}
                  variants={imageVariants}
                />
              </AspectRatioWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      }
    </div>
  );
};

export default SectionGallery;
